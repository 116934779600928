#notification-screen {
	display: flex;
	flex-wrap: wrap;
	gap: 1.2vw; /* Space between cards */
	padding: 16px;
	
	div {
		color: #333;
	}
  }

  .card {
	margin: 0.2vw; 
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
	min-height: 194px;
  }
  
  .quarter-card {
	flex: 1 1 calc(23vw);
	max-width: calc(23vw); /* Ensure cards don’t exceed intended width */
  }
  
  .half-card {
	flex: 1 1 calc(47.5vw); 
	max-width: calc(47.5vw); /* Ensure cards don’t exceed intended width */
  }

  .card_body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative; /* Enable absolute positioning for children */
	margin: 0;
	font-size: 1.5rem;
  }
  
  .card_body h3 {
	font-size: 1.5em;
	margin-top: 1rem;
    margin-bottom: 0;
  }
  
  .card_body .count {
	font-size: 5rem;
	margin: 1rem 0 1.75rem 0;
	padding: 0;
	line-height: 5rem;
	top: 50%; /* Position it 50% from the top of the parent */
	left: 50%; /* Position it 50% from the left of the parent */
	color: #333;
  }

  #checklist_progress {
	width: 100%;
	margin-bottom: 2rem;
  }

  .checklists {
	margin: 10px;
  }

  .checklist-row {
	padding: 1rem;
  }

  .checklists .title, .checklists .fraction {
	color: #333;
  }

  .checklists .label {
	  display: flex; /* Use flexbox inside .label to center the text */
	  align-items: center; /* Vertically center */
	  justify-content: center; /* Horizontally center */
	  text-align: center;
	  margin-right: 10px;
	  background-color: #0F79ED;
	  color: #fff;
	  border-radius: .75rem;
	  font-size: 1rem;
	  width: 25%;
	  float:left;
  }

  .progress {
    background-color: #efefef;
	border-radius: .5rem;
  }

  .progress-bar {
	background-color: #000;
	border-radius: .5rem;
	height: 1rem;
  }

  #calendar {
	width: 95%;
	color: #333;
  }

  #calendar ul li .description {
	margin-left: 20px;
	font-size: .8em;
  }

  .fraction {
	float:right;
	margin-right: 10px;
  }

  #running-low {
	margin: 10px;
  }

  .running-low-item {
	margin-left: 10px;
  }

 .text-blue {
	color: #0F79ED;
}

 .text-yellow {
	color: #f7b924;
}

 .text-red {
	color: #BA2424;
}

 .text-green {
	color: #3ac47d;
}

.app-header.header-shadow {
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 4, 11, 0.03), 0 0.9375rem 1.40625rem rgba(0, 4, 11, 0.03), 0 0.25rem 0.53125rem rgba(0, 4, 11, 0.05), 0 0.125rem 0.1875rem rgba(0, 4, 11, 0.03);
}
.app-header {
    height: 60px;
	width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.2s;
	margin-top: -10px;
	margin-left: -10px;
	padding: 0 10px;
}

.app-header .logo-src {
    height: 40px;
    width: 120px;
    background: url(../../assets/images/logo\ black\ blue.svg) no-repeat;
}

.app-header .app-header-right {
	color: #495057;
    margin-left: auto;
}

.app-header .app-header-right #clock {
	color: #495057;
	font-weight: 500;
    margin-left: auto;
	margin-right: 17px;
	margin-top: 3px;
}

.my-masonry-grid {
	display: flex;
	width: auto;
	margin-left: -1rem; /* adjust based on your grid gap */
	/* optional: you can add a transition for smoother layout */
  }
  
  .my-masonry-grid_column {
	padding: 0 1rem; /* adjust based on your grid gap */
	background-clip: padding-box;
  }
  
  .my-masonry-grid_column:first-of-type {
	padding-left: 2rem;
  }
  
  .my-masonry-grid_column:last-of-type {
	padding-right: 2rem;
  }
  
  /* Style each card inside the masonry grid */
  .my-masonry-grid_column > .card {
	margin: 1rem auto;
  }

  .queue .right-border::after {
	content: "";
	position: absolute;
	left: 50%;
	top: 10%; /* 10% top and bottom margin gives 80% height */
	height: 80%;
	border-right: 1px solid #ccc;
	transform: translateX(-50%);
  }